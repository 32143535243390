.pageContainer {
    margin-top: '1rem';
    margin-bottom: '1rem'
}

@media only screen and (max-width: 600px) {
    .iframeClass {
      width: 100%;
      height: 300px;
    }
  }

  @media only screen and (min-width: 600px) {
    .iframeClass {
      width: 600px;
      height: 400px;
    }
  }